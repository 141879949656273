<template>
  <img 
    :src="url || '#'" 
    :width="width" 
    :height="width" 
    class="grey-30 ra-app app-icon" 
  />
</template>

<script>
export default {
  name: 'AppIcon',

  props: {
    url: {
      type: String
    },

    width: {
      type: Number,
      default: 154
    },

    height: {
      type: Number,
      default: 154
    }
  }
}
</script>

<style scoped>
img.app-icon {
  overflow: hidden;
  position: relative;
}
img.app-icon:after {  
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: var(--grey-60);
}
</style>
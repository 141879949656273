import Axios from 'axios'

import store from '@/store'
import Environment from '@/environment'

window.Axios = Axios

Axios.defaults.baseURL = Environment.API_URL

Axios.interceptors.request.use(function (config) {
  const token = store.state.layers.token
  const communityId = store.state.layers.communityId

  config.headers['X-Layers-App-Store-Segment'] = 'education'

  // Add community-id
  if(communityId) {
    config.headers['X-Community-id'] = communityId
  }

  // Add token
  if(token){
    config.headers['Authorization'] = 'Bearer ' + token
  }

  return config;
})

export default Axios

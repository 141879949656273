<template>
  <div 
    class="promotional-card ls-d-flex grey-30 ls-align-items-center ls-justify-content-center" 
    :style="{ backgroundImage: 'url(' + promotionalBackgroundUrl + ')' }">
    <div class="text-center">
      <div 
        class="promotional-title mx-4 text-center"
        :class="color + '--text'" 
        v-if="promotional.title">
        {{ promotional.title }}
      </div>

      <router-link :to="{ name: 'collection', params: { alias: alias } }">
        <l-button class="promotional-action-button" :class="`${color}-outline ${color}--text`">
          Ver todos
        </l-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'PromotionalCard',

  props: {
    promotional: {
      type: Object,
      required: true
    },

    alias: {
      type: String,
      required: true
    }
  },

  computed: {
    promotionalBackgroundUrl() {
      return _.get(this.promotional, 'background.url', null)
    },

    color() {
      return {
        'dark': 'lead',
        'light': 'white'
      } [_.get(this.promotional, 'mode', 'dark')] || 'dark'
    }
  }
}
</script>

<style scoped>
.promotional-card {
  width: 100%;
  height: 192px;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
}
.promotional-title {
  min-height: 56px;
  font-size: 20px;
  font-weight: 800;
  line-height: 110%;
}
.promotional-action-button {
  margin-top: 8px;
  width: 152px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid;
  font-weight: bold;
}
.promotional-action-button.white--text:hover,
.promotional-action-button.white--text:focus,
.promotional-action-button.white--text:active {
  background-color: var(--lead);
}
</style>
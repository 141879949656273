<template>
  <div class="ls-d-flex ls-flex-wrap">
    <div class="ls-col-md-3 ls-col-xs-12 mb-4">
      <div class="app-icon ls-d-flex ls-align-items-center ls-justify-content-center mb-6">
        <AppIcon
          :url="app && app.icon && app.icon.url"
        />
      </div>

      <div
        class="app-title f-20 lead--text mb-2 ls-align-items-center ls-justify-content-center ls-d-flex ls-d-sm-flex ls-d-md-none ls-d-lg-none">
        <template v-if="app && app.displayName">
          {{ app.displayName }}
        </template>
        <template v-else>
          <van-skeleton title class="px-0" v-for="index in 2" :key="index" />
        </template>
      </div>

      <div class="app-installation">
        <v-menu offset-y :open-on-hover="hasInstallationActions">
          <template v-slot:activator="{ on, attrs }">
            <v-layout v-bind="attrs" v-on="on">
              <InstallationButton
                @click.native="!hasInstallationActions ? callInstallationStatusAction() : null"
                :installationStatus="app && app.installation && app.installation.status"
                :approval="app && app.approval"
                :loading="$wait.waiting('updatingInstallationStatus')"
              />
            </v-layout>
          </template>
          <v-list v-if="hasInstallationActions">
            <v-list-item
              class="cursor-pointer"
              @click.native="callInstallationStatusAction"
            >
              <v-list-item-title>{{ installationStatusActionLabel }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div class="app-installation-by mt-2" v-if="installationLabel">
          {{ installationLabel }} {{ installedByName ? 'por ' : '' }} <b>{{ installedByName }}</b> {{ installedAtLabel }}
        </div>
      </div>

      <div class="app-features mt-4">
        <template v-if="!app">
          <van-skeleton title :row="2" class="px-0 mb-3" />
          <van-skeleton :row="2" v-for="index in 2" :key="index" class="px-0 mb-3" />
        </template>

        <template v-else-if="app && app.features && app.features.length">
          <div class="section-title f-16 lead--text">
            Funcionalidades
          </div>

          <div class="app-feature lead--text f-14 ls-d-flex" v-for="(feature, i) in app.features" :key="i">
            <svg class="ls-flex-none" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M8.49998 0.583252C4.14581 0.583252 0.583313 4.14575 0.583313 8.49992C0.583313 12.8541 4.14581 16.4166 8.49998 16.4166C12.8541 16.4166 16.4166 12.8541 16.4166 8.49992C16.4166 4.14575 12.8541 0.583252 8.49998 0.583252ZM11.7458 7.07492L7.78748 11.0333C7.62915 11.1916 7.47081 11.2708 7.23331 11.2708C6.99581 11.2708 6.83748 11.1916 6.67915 11.0333L4.85831 9.13325L4.77915 9.05408C4.46248 8.73742 4.54165 8.26242 4.85831 7.94575C5.17498 7.62909 5.64998 7.70825 5.96665 8.02492L7.23331 9.37075L10.6375 5.96659C10.9541 5.64992 11.4291 5.64992 11.7458 5.96659C12.0625 6.28325 12.0625 6.75825 11.7458 7.07492Z" fill="var(--success)"/>
            </svg>
            <span class="ml-2">{{ feature.name }}</span>
          </div>
        </template>
      </div>

      <div class="app-integrates mt-4">
        <template v-if="!app">
          <van-skeleton title :row="2" class="px-0 mb-3" />
          <van-skeleton :row="2" v-for="index in 2" :key="index" class="px-0 mb-3" />
        </template>

        <template v-else-if="app && app.integrates && app.integrates.length">
          <div class="section-title f-16 lead--text mb-2">
            Interage com os apps
          </div>

          <div
            class="app-integrated f-14 ls-d-flex mb-2 ls-align-items-center"
            v-for="appIntegrated in app.integrates"
            @click="openApp(appIntegrated.id)"
            :key="appIntegrated.id">
            <AppIcon
              :url="appIntegrated && appIntegrated.icon && appIntegrated.icon.url"
              :width="24"
              :height="24"
            />
            <span class="ml-2">{{ appIntegrated.displayName }}</span>
          </div>
        </template>
      </div>

      <div class="app-category mt-5" v-if="app && app.category && app.category.name">
        <div class="section-title f-16 lead--text mb-2">
          Categoria
        </div>
        <l-chip
          class="category-chip"
          color="lead-light"
          outline
          hide-icon>
          {{ app.category.name }}
        </l-chip>
      </div>

      <div class="app-privacy-policy mt-4 ls-d-flex ls-align-items-center" v-if="app && app.privacyPolicy">
        <a class="lead--text f-14 underline" :href="app.privacyPolicy" target="_blank">Política de privacidade</a>
      </div>

      <div class="app-terms-of-service ls-d-flex ls-align-items-center" v-if="app && app.termsOfService">
        <a class="lead--text f-14 underline" :href="app.termsOfService" target="_blank">Termos de serviço</a>
      </div>

      <div class="app-support-email ls-d-flex ls-align-items-center" v-if="app && app.supportEmail">
        <a class="link--text f-14" :href="'mailto:' + app.supportEmail">{{ app.supportEmail }}</a>
      </div>
    </div>

    <div class="ls-col-md-9 ls-col-xs-12">
      <div class="app-title lead--text ls-d-none ls-d-sm-none ls-d-md-flex ls-d-lg-flex">
        <template v-if="app && app.displayName">
          {{ app.displayName }}
        </template>
        <template v-else>
          <van-skeleton title class="px-0" v-for="index in 2" :key="index" />
        </template>
      </div>

      <div class="app-caption ls-d-flex ls-align-items-center ls-flex-wrap" v-if="app">
        <div
          class="app-author lead-light--text f-14 ls-d-flex ls-align-items-end ls-flex-wrap mr-5 pb-2"
          v-if="app && app.author && app.author.name">
          <v-icon color="grey-60" size="20" class="mr-1">uil-check</v-icon>
          <span>Desenvolvido por</span>
          <div
            class="link--text bold ml-1 cursor-pointer"
            @click.stop="$router.push({ name: 'author', params: { id: app.author.id, author: app.author }, query: { authorName: app && app.author && app.author.name } })">
            {{ app.author.name }}
          </div>
        </div>

        <div class="app-price lead-light--text f-14 ls-d-flex ls-align-items-end ls-flex-wrap pb-2">
          <v-icon color="grey-60" size="20" class="mr-1">uil-check</v-icon>
          <span>Gratuito</span>
        </div>
      </div>

      <div class="app-content pb-5">
        <l-tabs v-model="currentTab" v-if="app">
          <l-tab-pane label="Informações gerais" name="0">
            <div class="lead--text">{{ app && app.description }}</div>

            <div class="app-attachments mt-5">
              <img
                v-for="attachment in app.attachments"
                :key="attachment.id"
                :src="attachment.thumb || attachment.url"
                :width="attachment.width"
                :height="attachment.height"
                class="mb-2"
              />
            </div>
          </l-tab-pane>
        </l-tabs>

        <template v-else>
          <van-skeleton :row="4" v-for="index in 3" :key="index" class="px-0 mb-3" />
          <van-skeleton :row="2" v-for="index in 2" :key="index" class="px-0 mb-3" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InstallationButton from '@/components/InstallationButton'
import AppIcon from '@/components/AppIcon'
import Environment from '@/environment'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import Toast from '@/helpers/toast'
import { mapWaitingActions } from 'vue-wait'

export default {
  name: 'AppContent',

  components: {
    AppIcon,
    InstallationButton
  },

  data() {
    return {
      currentTab: 0
    }
  },

  props: {
    app: {
      type: Object,
    }
  },

  computed: {
    ...mapGetters('layers', ['isAuthenticated']),

    installationLabel(){
      if(!this.app ||
         !this.app.installation ||
         !this.app.installation.status ||
         !this.app.installation.status == 'uninstalled') return

      if(['installed', 'pending_update'].includes(this.app.installation.status)){
        return 'Instalado'
      }
      if(['pending_approval'].includes(this.app.installation.status)){
        return 'Solicitado'
      }

      return ''
    },

    installedByName() {
      return _.get(this.app, 'installation.installedBy.name', null)
    },

    installedAtLabel() {
      const installedAt = _.get(this.app, 'installation.installedAt', null)
      if(!installedAt) return
      dayjs.extend(relativeTime)

      return dayjs(installedAt).locale('pt-br').fromNow()
    },

    hasInstallationActions() {
      const status = _.get(this.app, 'installation.status', null)
      if(!status) return false

      return _.includes(['installed', 'pending_update', 'pending_approval'], status)
    },

    installationStatusActionLabel() {
      if(!this.hasInstallationActions) return
      return {
        'installed': 'Desinstalar',
        'pending_update': 'Atualizar',
        'pending_approval': 'Desinstalar'
      }[this.app.installation.status]
    },
  },

  methods: {
    ...mapActions('appstore', ['fetchApp', 'fetchApps', 'installApp', 'uninstallApp', 'updateApp']),
    ...mapWaitingActions('appstore', {
      installApp: 'updatingInstallationStatus',
      uninstallApp: 'updatingInstallationStatus',
      updateApp: 'updatingInstallationStatus',
    }),

    openApp(appId){
      this.$router.push({
        name: 'app',
        params: {
          appId: appId
        }
      })
    },

    async callInstallationStatusAction() {
      if(!this.isAuthenticated){
        return window.location = Environment.LAYERS_ID_URL
      }

      const status = _.get(this.app, 'installation.status', null)
      const approval = _.get(this.app, 'approval', null)
      const neededConsents = _.get(this.app, 'neededConsents', [])
      if((neededConsents.length > 0 || approval == 'manual') && status == 'uninstalled'){
        return this.$emit('updateNeededConsents', true)
      }

      let action
      switch (status){
        case 'uninstalled':
          action = await this.installApp({ appId: this.app.id })
          break;
        case 'installed':
          action = await this.uninstallApp(this.app.id)
          break;
        case 'pending_update':
          action = await this.updateApp(this.app.id)
          break;
        case 'pending_approval':
          action = await this.uninstallApp(this.app.id)
          break;
      }

      if(!action) return

      let successMessage = {
        'uninstalled': 'Seu app foi instalado com sucesso. Aproveite!',
        'installed': 'Este app foi desinstalado.',
        'pending_update': 'A atualização desse app foi realizada.',
        'pending_approval': 'Este app foi desinstalado.'
      }[status]

      Toast.open({
        options: {
          prependIcon: 'check',
        },
        message: successMessage,
        type: 'success',
        position: 'bottom',
        timeout: 5000
      })
      this.$emit('updateAppInfo')

      if(status == 'uninstalled') {
        /* global LayersPortal */
        LayersPortal('go', `/settings/app/${this.app.id}?tab=settings`)
      }
    },
  }
}
</script>

<style scoped>
.section-title {
  height: 32px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.app-feature {
  min-height: 32px;
  padding-top: 7px;
}
.app-feature > svg {
  margin-top: 2px;
}
.app-integrates {
  min-height: 24px;
}
.app-integrated {
  position: relative;
  color: var(--lead);
  cursor: pointer;
  z-index: 2;
}
.app-integrated:hover > span {
  color: var(--aqua-dark);
  z-index: 2;
}
.app-integrated::before{
  content: '';
  transition: 0.3s background ease-in-out;
}
.app-integrated:hover::before{
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -4px;
  margin-bottom: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border-radius: 4px;
  background-color: var(--aqua-opacity);
  z-index: 0;
}
.category-chip {
  min-height: 32px;
}
.category-chip > .text {
  font-size: 14px;
}
.app-privacy-policy, .app-terms-of-service, .app-support-email{
  min-height: 32px;
}
.app-title {
  font-size: 36px;
  font-weight: bold;
  line-height: 120%;
  min-height: 40px;
}
.app-attachments > img {
  max-width: 100%;
  height: auto;
}
</style>
<template>
  <v-navigation-drawer
    :value="navigationDrawer"
    @input="updateDrawer($event)"
    color="white"
    app
    clipped
    floating
    class="main-menu m-0"
    :class="{ 'overlay-menu': isOverlayMenu }"
    :width="202"
    :mobile-breakpoint="menuBreakpoint"
  >
    <div class="section">
      <template v-if="collections && collections.length">
        <div class="section-title">Coleções</div>
        <ul class="section-menu">        
          <li 
            v-for="collection in allCollections" 
            :key="collection.alias"
            :class="{ 'active': isActiveCollection(collection.alias) }">
            <router-link 
              class="lead-light--text" 
              :to="collection.alias == '*' ? '/' : '/collection/' + collection.alias">
              {{ collection.name }}
            </router-link>
          </li>
        </ul>
      </template>

      <template v-else>
        <van-skeleton title :row="2" class="px-0 mb-3" />
        <van-skeleton :row="2" v-for="index in 2" :key="index" class="px-0 mb-3" />
      </template>
    </div>

    <div class="section mt-6">
      <template v-if="categories && categories.length">
        <div class="section-title">Categorias</div>
        <ul class="section-menu">        
          <li 
            v-for="category in categories" 
            :key="category.slug"
            :class="{ 'active': isActiveCategory(category.slug) }">
            <router-link class="lead-light--text" :to="'/category/' + category.slug">
              {{ category.name }}
            </router-link>
          </li>
        </ul>
      </template>

      <template v-else>
        <van-skeleton title :row="2" class="px-0 mb-3 mt-10 pt-10" />
        <van-skeleton :row="2" v-for="index in 2" :key="index" class="px-0 mb-3" />
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Navigation',

  created() {
    this.fetchInfo()
  },
  
  computed: {
    ...mapState('appstore', ['collections', 'categories', 'drawer', 'menuBreakpoint']),

    allCollections() {
      let collections = [{
        alias: '*',
        name: 'Todos os apps'
      }]

      return [...collections, ...this.collections]
    },

    navigationDrawer() {
      if(!this.isOverlayMenu){
        return true
      }
      return this.drawer
    },

    isOverlayMenu() {
      if(this.$vuetify.breakpoint.width < this.menuBreakpoint){
        return true
      }

      return false
    }
  },

  methods: {
    ...mapActions('appstore', ['fetchInfo']),

    updateDrawer(value){
      return this.$store.commit('appstore/setDrawer', value)
    },

    isActiveCollection(alias){
      if(alias == '*' && this.$route && this.$route.name == 'home') return true
      
      if(!this.$route || !this.$route.name || this.$route.name != 'collection') return
      return _.get(this.$route, 'params.alias', null) == alias
    },

    isActiveCategory(slug){
      if(!this.$route || this.$route.name != 'category') return
      return _.get(this.$route, 'params.slug', null) == slug
    }
  }
  
}
</script>

<style scoped>
.main-menu {
  padding-left: 24px;
}
.overlay-menu {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
}
.section-title {
  height: 32px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
ul.section-menu {
  padding: 0;
}
ul.section-menu > li {
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  padding: 0px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
ul.section-menu > li.active {
  background: var(--aqua-opacity);
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}
ul.section-menu > li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
ul.section-menu > li.active a {
  color: var(--aqua-dark)!important;
}
</style>
<template>
  <div class="home-page ls-container" :class="{ 'pt-4': $vuetify.breakpoint.lgAndUp }">
    <Banner isHome />
    <CollectionFeed />
    <AppFeed />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppFeed from '@/components/AppFeed'
import CollectionFeed from '@/components/CollectionFeed'
import Banner from '@/components/Banner'

export default {
  name: 'Home',

  components: {
    AppFeed,
    CollectionFeed,
    Banner
  },

  created() {
    this.fetchCatalog()
  },

  methods: {
    ...mapActions('appstore', ['fetchCatalog'])
  },

}
</script>


import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
import VueWait from 'vue-wait'

import layers from './layers'
import appstore from './appstore'

Vue.use(Vuex)
Vue.use(VueWait)

export default new Vuex.Store({
  modules: {
    layers,
    appstore,
  },
  strict: true,
  plugins: [
    persistedState({
      key: 'layers-app-store'
    })
  ]
})
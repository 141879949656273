<template>
  <div 
    class="banner ls-d-flex" 
    :style="{ 
      background: backgroundImage,
      paddingTop: ((bannerHeight / bannerWidth) * 100) + '%'
    }">
    <img class="banner-home" src="@/assets/img/banner-home.svg" width="856" height="216" v-if="isHome" />

    <div class="banner-content">
      <div class="banner-title" :class="titleColor + '--text'" v-if="banner && banner.title">
        {{ banner.title }}
      </div>
      <div class="banner-description mt-2" :class="descriptionColor + '--text'" v-if="banner && banner.description">
        {{ banner.description }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Banner',

  props: {
    banner: {
      type: Object,
    },

    isHome: {
      type: Boolean
    }
  },

  computed: {
    bannerHeight() {
      return _.get(this.banner, 'background.height', 216)
    },

    bannerWidth() {
      return _.get(this.banner, 'background.width', 856)
    },

    bannerBg() {
      return _.get(this.banner, 'background.url', null)
    },

    backgroundImage() {
      return 'url(' + this.bannerBg + ') no-repeat'
    },

    titleColor() {
      return {
        'dark': 'lead',
        'light': 'white'
      } [_.get(this.banner, 'mode', 'dark')] || 'dark'
    },

    descriptionColor() {
      return {
        'dark': 'lead-light',
        'light': 'white'
      } [_.get(this.banner, 'mode', 'dark')] || 'dark'
    }
  }
}
</script>

<style scoped>
.banner {
  width: 100%;
  height: auto;
  position: relative;
  max-height: 216px;
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--grey-30);
  background-size: contain!important;
}
.banner-home {
  position: absolute;
  top: 0px;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.banner-content {
  position: absolute;
  top: 0px;
  padding: 12px;
}
.banner-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
}
.banner-description {
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
}

@media (min-width: 576px) {
  .banner-content {
    padding: 24px;
  }
  .banner-title {
    font-size: 24px;
    width: 380px;
  }
  .banner-description {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .banner-content {
    padding: 32px;
  }
  .banner-title {
    font-size: 32px;
  }
  .banner-description {
    font-size: 18px;
  }
}
</style>
<template>
  <div class="not-found ls-text-center mt-5 ls-align-items-center ls-justify-content-center">
    <h1 class="lead--text f-16 mb-2">{{ title }}</h1>
    <div class="lead-light--text f-14 mb-5">
      {{ message }}
    </div>
    <l-button 
      @click="actionFunction" 
      dark 
      depressed 
      height="40" 
      width="220"
      block 
      class="mb-6 aqua bold reset-text-transform f-16 white--text">
      {{ actionLabel }}
    </l-button> 
    <img src="@/assets/img/not-found.svg" width="214" height="214" class="mt-5" />
  </div>
</template>

<script type="text/javascript">
import router from '@/router'
export default {
  name: 'NotFound',

  props: {
    title: {
      type: String,
      default: 'Algo deu errado :('
    },
    
    message: {
      type: String,
      default: 'Não encontramos a página que você está tentando acessar. Atualize a página ou verifique sua conexão com a internet.'
    },

    actionFunction: {
      type: Function,
      default: () => {
        return router.push({ path: '/' })
      }
    },

    actionLabel: {
      type: String,
      default: 'Voltar à home'
    }
  }
}
</script>

<style type="text/css" scoped>
.not-found {
  width: 250px;
  height: 100%;
  margin: 0 auto;
}
</style>
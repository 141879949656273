<template>
  <div class="category-page ls-container" :class="{ 'pt-4': $vuetify.breakpoint.lgAndUp }">
    <Banner :banner="category && category.banner" />
    <AppFeed class="mt-5" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppFeed from '@/components/AppFeed'
import Banner from '@/components/Banner'
import _ from 'lodash'

export default {
  name: 'Category',

  components: {
    AppFeed,
    Banner
  },

  data() {
    return {
      category: null
    }
  },

  watch:{
    '$route.params.slug'() {
      this.updateCategory()
    }
  },

  created() {
    this.updateCategory()
  },

  computed: {
    ...mapState('appstore', ['categories'])
  },

  methods: {
    ...mapActions('appstore', ['fetchCatalog']),

    updateCategory() {
      let category = _.get(this.$route, 'params.category', null)
      let slug = _.get(this.$route, 'params.slug', null)
      if(slug == (this.category && this.category.slug)) return

      if(!category) {
        category = this.categories.find(c => c.slug == slug)
      }

      this.category = category
      this.fetchCatalog({ category: category.slug })
    }
  },

}
</script>


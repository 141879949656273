/* global LayersPortal */

const state = {
  session: null,
  userId: null,
  communityId: null,
  accountId: null,
  connected: false,
  token: null
}

const mutations = {
  setSession(context, session) {
    context.session = session
  },

  setUserId(context, userId) {
    context.userId = userId
  },

  setCommunityId(context, communityId) {
    context.communityId = communityId
  },

  setAccountId(context, accountId) {
    context.accountId = accountId
  },

  setConnected(context, connected) {
    context.connected = connected
  },

  setToken(context, token) {
    context.token = token
  }
}

const actions = {
  async init(context) {
    context.commit('setConnected', 'loading')
    try {
      if(!LayersPortal) {
        throw new Error('LayersPortal is not defined')
      }

      await LayersPortal.readyPromise
      if (!LayersPortal.platform || !LayersPortal.connected) {
        throw new Error('Layers Portal not connected')
      }

      await LayersPortal.connectedPromise
      const session = LayersPortal.session
      const token = await LayersPortal('getAccountToken')
      const communityId = LayersPortal.communityId
      const userId = LayersPortal.userId
      const accountId = LayersPortal.accountId
      const connected = LayersPortal.connected

      context.dispatch('updateSession', {
        session,
        communityId,
        userId,
        accountId,
        connected,
        token
      })
    } catch (err) {
      context.commit('setConnected', false)
      return context.dispatch('updateSession', {
        session: null,
        communityId: null,
        userId: null,
        accountId: null,
        connected: null,
        token: null
      })
    }
  },
  async updateSession(context, { session, communityId, userId, accountId, connected, token }) {
    context.commit('setSession', session)
    context.commit('setCommunityId', communityId)
    context.commit('setUserId', userId)
    context.commit('setAccountId', accountId)
    context.commit('setConnected', connected)
    context.commit('setToken', token)
  }
}

const getters = {
  isAuthenticated(state) {
    const session = state.session
    const token = state.token
    const communityId = state.communityId
    const userId = state.userId

    return !!(session && communityId && userId && token)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
<template>
  <div class="apps">
    <template v-if="appsIds && appsIds.length && apps && apps.length">
      <AppCard
        v-for="(appId, i) in appsIds"
        :app="apps.find(app => app.id == appId)"
        @click.native="openApp(appId, apps.find(app => app.id == appId))"
        :key="i"
      />
    </template>

    <template v-else>
      <div 
        class="skeleton-app grey-30-outline"
        v-for="index in 9"
        :key="index"
      >
        <van-skeleton 
          title
          avatar 
          :row="3" 
          class="p-0"
        />
      </div>
    </template>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'AppFeed',
  
  components: {
    AppCard
  },

  computed: {
    parentComponentName() {
      const routes = router && router.options && router.options.routes
      if(!routes || !routes.length) return 'Home'
      return (routes.find(route => route.name == this.$route.name)).components.default.name
    },
    
    parentParams() {
      return this.$route.params
    },

    ...mapState('appstore', ['apps', 'appsIds']),
  },

  methods:{
    openApp(appId, app){
      return this.$router.push({ 
        name: 'app', 
        params: { 
          appId: appId, 
          app: app,
          default: this.parentComponentName,
          ...this.parentParams
        }
      })
    }
  }
}
</script>

<style scoped>
.apps {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 16px;
}
.skeleton-app {
  border: 1px solid;
  border-radius: 12px;
  padding: 24px;
}

@media (min-width: 576px) {
  .apps {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .apps {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-bottom: 32px;
  }
}

@media (min-width: 992px) {
  .apps {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    margin-bottom: 32px;
  }
}
</style>
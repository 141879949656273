<template>
  <div class="collection-page ls-container" :class="{ 'pt-4': $vuetify.breakpoint.lgAndUp }">
    <Banner :banner="collection && collection.banner" />
    <AppFeed class="mt-5" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppFeed from '@/components/AppFeed'
import Banner from '@/components/Banner'
import _ from 'lodash'

export default {
  name: 'Collection',

  components: {
    AppFeed,
    Banner
  },

  data() {
    return {
      collection: null
    }
  },

  watch:{
    '$route.params.alias'() {
      this.updateCollection()
    }
  },

  created() {
    this.updateCollection()
  },

  computed: {
    ...mapState('appstore', ['collections'])
  },

  methods: {
    ...mapActions('appstore', ['fetchCatalog']),

    updateCollection() {
      let collection = _.get(this.$route, 'params.collection', null)
      let alias = _.get(this.$route, 'params.alias', null)
      if(alias == (this.collection && this.collection.alias)) return

      if(!collection) {
        collection = this.collections.find(c => c.alias == alias)
      }

      this.collection = collection
      this.fetchCatalog({ collection: collection.alias })
    },
    
  },

}
</script>


import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import Home from '@/views/Home'
import AppDetail from '@/views/AppDetail/index'
import Category from '@/views/Category'
import Collection from '@/views/Collection'
import Author from '@/views/Author'

import DynamicView from '@/components/DynamicView'
import NavigationMenu from '@/components/NavigationMenu'
import Appbar from '@/components/Appbar'

// NotFound
import NotFound from '@/views/NotFound'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [
  {
    alias: '/',
    path: '/home',
    name: 'home',
    components: {
      default: Home,
      navigation: NavigationMenu,
      header: Appbar
    },
  },

  {
    path: '/app/:appId',
    name: 'app',
    components: {
      default: DynamicView,
      app: AppDetail,
      navigation: NavigationMenu,
      header: Appbar
    },
    props: { default: true, sidebar: false }
  },

  {
    path: '/category/:slug',
    name: 'category',
    components: {
      default: Category,
      navigation: NavigationMenu,
      header: Appbar
    },
  },

  {
    path: '/collection/:alias',
    name: 'collection',
    components: {
      default: Collection,
      navigation: NavigationMenu,
      header: Appbar
    },
  },

  {
    path: '/author/:id',
    name: 'author',
    components: {
      default: Author,
      navigation: NavigationMenu,
      header: Appbar
    },
  },

  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
      auth: false,
      title: 'Não encontrado'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from) {
    if(to && to.name != 'app' && from && from.name != 'app') {
      document.getElementById('app').scrollIntoView();
      return null;
    }
  }
})


export default router

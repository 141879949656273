<template>
  <v-app-bar
    app
    clipped-left
    flat
    color="white"
    dense
    :height="72"
    class="main-app-bar"
    :class="{ 'mobile-app-bar': isMobile }"
  >
    <div 
      class="navbar-max-width ls-d-flex" 
      :class="isMobile ? 'ls-flex' : 'ls-flex-none'"
      v-if="(!showSerchbar && isMobile) || !isMobile">
      <v-btn @click="setDrawer(true)" icon v-if="showMenu || (showSerchbar && isMobile)" class="mr-2">
        <v-icon>uil-bars</v-icon>
      </v-btn>

      <router-link class="ls-d-flex ls-align-items-center logo" :to="{ name: 'home' }">
        <img src="@/assets/img/logo.svg" width="24" height="24" />
        <b class="ml-2 f-16 aqua--text">Layers Store</b>
      </router-link>
    </div>

    <div v-if="showSerchbar || !isMobile" :class="!isMobile ? 'ls-container' : 'ls-d-flex search-bar-container'">
      <v-btn @click="showSerchbar = false" icon v-if="showSerchbar && isMobile">
        <v-icon>uil-multiply</v-icon>
      </v-btn>
      <div class="search-bar">
        <v-combobox
          v-model="q"
          :items="searchResults"
          outlined
          prepend-inner-icon="uil-search"
          placeholder="Busque por um app"
          height="48"
          min-height="48"
          dense
          hide-details
          item-text="displayName"
          item-value="id"
          @input="openApp($event.id)"
          class="search-bar-input"
          color="link"
          @update:search-input="searchAppsDebounce"
        >
        </v-combobox>
      </div>
    </div>

    <v-btn @click="showSerchbar = true" icon v-else-if="!showSerchbar">
      <v-icon>uil-search</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState, mapMutations } from 'vuex'
import router from '@/router'

export default {
  name: 'Appbar',

  data() {
    return {
      showSerchbar: false,
      q: null
    }
  },

  computed: {
    ...mapState('appstore', ['searchResults', 'drawer', 'menuBreakpoint']),

    showMenu() {
      if(this.$vuetify.breakpoint.width < this.menuBreakpoint){
        return true
      }

      return false
    },

    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },

    parentParams() {
      return this.$route.params
    },

    parentComponentName() {
      const routes = router && router.options && router.options.routes
      if(!routes || !routes.length) return 'Home'
      return (routes.find(route => route.name == this.$route.name)).components.default.name
    },
    
  },

  methods: {
    ...mapMutations('appstore', ['setDrawer']),
    ...mapActions('appstore', ['searchApps']),

    openApp(appId) {
      return this.$router.push({ 
        name: 'app', 
        params: { 
          appId: appId, 
          default: this.parentComponentName,
          ...this.parentParams
        }
      })
    },

    searchAppsDebounce: _.debounce(function (event) {
      this.searchApps(event)
    }, 500, { leading: false, trailing: true }),

  }
}
</script>
<style scoped>
.logo {
  height: 48px;
  padding: 0px;
}
.search-bar, .search-bar-container {
  width: 100%;
}
@media (min-width: 576px) {
  .logo {
    padding-left: 18px;
  }
  .search-bar {
    width: 360px;
  }
}
</style>
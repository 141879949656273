<template>
  <l-chip 
    class="bold f-16"
    :color="color" 
    fill
    :icon="icon">
    {{ label }}
  </l-chip>
</template>

<script>
export default {
  name: 'InstallationStatusChip',

  props: {
    installationStatus: {
      type: String,
      required: true
    }
  },

  computed: {
    label() {
      return {
        'installed': 'Instalado',
        'pending_approval': 'Em aprovação',
        'pending_update': 'Atualizar'
      }[this.installationStatus]
    },

    color() {
      return {
        'installed': 'success',
        'pending_approval': 'warning',
        'pending_update': 'warning'
      }[this.installationStatus] || 'lead'
    },

    icon() {
      return {
        'installed': 'uil-check',
        'pending_approval': 'uil-clock',
        'pending_update': 'uil-arrow-circle-down'
      }[this.installationStatus] || 'uil-exclamation-octagon'
    },
  }
}
</script>
<template>
  <div class="not-found-page">
    <NotFoundComponent 
      :title="title" 
      :message="message"
      :actionFunction="actionFunction"
      :actionLabel="actionLabel"
    />
  </div>
</template>
<script type="text/javascript">
import NotFoundComponent from '@/components/NotFound'
import _ from 'lodash'

export default {
  name: 'NotFound',

  computed: {
    title() {
      return _.get(this.$route, 'query.title', undefined)
    },

    message() {
      return _.get(this.$route, 'query.message', undefined)
    },

    actionFunction() {
      let fn = _.get(this.$route, 'query.actionFunction', undefined)
      if(typeof fn != 'function' && fn != undefined) {
        return new Function(fn)
      }
      return fn
    },

    actionLabel() {
      return _.get(this.$route, 'query.actionLabel', undefined)
    }
  },
  
  components: {
    NotFoundComponent
  }
}
</script>
<style type="text/css" scoped>
.not-found-page {
  min-height: 100vh;
}
</style>
<script>
import Collection from '@/views/Collection'
import Author from '@/views/Author'
import Category from '@/views/Category'
import Home from '@/views/Home'

const components =
{
    Home,
    Author,
    Category,
    Collection,
}

export default
{
    functional: true,
    props: {
        default: {
            type: String,
            default: 'Home'
        },
    },
    render (createElement, context) {
        var view = context.props.default
        return createElement((components[view] || Home), context.data, context.children)
    }
}
</script>
import Axios from 'axios'
import errorHandler from '@/helpers/errorHandler'
import _ from 'lodash'

const state = {
  menuBreakpoint: 990,
  loading: false,
  drawer: false,

  appsIds: [],
  apps: [],
  categories: [],
  collections: [],
  searchResults: [],
  segment: null,
}

// mutations
const mutations = {
  setLoading(state, status) {
    state.loading = status
  },

  setDrawer(state, drawer){
    state.drawer = drawer
  },

  setAppsIds(state, appsIds) {
    state.appsIds = appsIds
  },

  setApps(state, apps) {
    state.apps = apps
  },

  pushToApps(state, apps) {
    state.apps.push(...apps)
    state.apps = _.uniq(state.apps)
  },

  setCategories(state, categories) {
    state.categories = categories
  },

  setCollections(state, collections) {
    state.collections = collections
  },

  setSearchResults(state, searchResults) {
    state.searchResults = searchResults
  },

  setSegment(state, segment) {
    state.segment = segment
  },
}

// actions
const actions = {
  async fetchInfo(context) {
    context.commit('setLoading', true)
    try {
      const { data } = await Axios.get('/appstore/info')
      context.commit('setCollections', data.collections)
      context.commit('setCategories', data.categories)
      context.commit('setSegment', data.segment)
      context.commit('setLoading', false)
    } catch(err) {
      errorHandler({
        error: err,
        parameters: {
          callback: () => context.dispatch('fetchInfo'),
          action: { label: 'Tentar novamente' },
          timeout: 4000
        }
      })
      context.commit('setLoading', false)
    }
  },

  async fetchCatalog(context, { collection, category, author } = {}) {
    try {
      const { data } = await Axios.get('/appstore/catalog', {
        params: {
          category,
          collection,
          author
        }
      })
      context.commit('setAppsIds', data)

      const stateAppsIds = context.state.apps.map(app => app.id)
      const appsIdsToPopulate = data.filter(appId => !stateAppsIds.includes(appId))

      if(appsIdsToPopulate.length){
        context.dispatch('fetchApps', appsIdsToPopulate)
      }
    } catch(err) {
      errorHandler({
        error: err,
        parameters: {
          callback: () => context.dispatch('fetchCatalog'),
          action: { label: 'Tentar novamente' },
          timeout: 4000
        }
      })
    }
  },

  async fetchApps(context, appsIds){
    try {
      const { data: apps } = await Axios.get('/appstore/apps', {
        params: {
          ids: appsIds,
        }
      })

      const isAuthenticated = context.rootGetters['layers/isAuthenticated']
      if(isAuthenticated) {
        const { data: installations } = await Axios.get('/appstore/installations', {
          params: {
            ids: appsIds,
          }
        })

        apps.forEach(app => {
          const appInstallation = installations.find(installation => installation.id == app.id)
          app.installationStatus = _.get(appInstallation, 'status', null)
        })
      }

      context.commit('pushToApps', apps)
    } catch(err) {
      errorHandler({
        error: err,
        parameters: {
          callback: () => context.dispatch('fetchApps'),
          action: { label: 'Tentar novamente' },
          timeout: 4000
        }
      })
    }
  },

  async fetchApp(context, { appId, retryParams, onError = function(){}  } = {}) {
    try {
      const { data: app } = await Axios.get(`/appstore/apps/${appId}`)

      const isAuthenticated = context.rootGetters['layers/isAuthenticated']
      if(isAuthenticated) {
        const { data: installation } = await Axios.get(`/appstore/installations/${appId}`)
        app.installationStatus = _.get(installation, 'status', null)
        app.installation = installation
      } else {
        app.installation = null
      }

      // Update app 
      const apps = [...context.state.apps]
      const appIndex = _.findIndex(apps, { id: app.id })
      if(appIndex >= 0) {
        _.set(apps, appIndex, Object.assign({}, app))
        context.commit('setApps', apps)
      }

      return app
    } catch(err) {
      onError()
      errorHandler({
        error: err,
        parameters: retryParams
      })
    }
  },

  async searchApps(context, q){
    try {
      const { data: searchResults } = await Axios.get('/appstore/search', {
        params: {
          q,
        }
      })

      if(searchResults.length) {
        context.commit('setSearchResults', searchResults)
      }
    } catch(err) {
      context.commit('setSearchResults', [])
      errorHandler({
        error: err,
        parameters: {
          fixedMessageError: 'Erro ao buscar. Tente novamente',
          timeout: 4000
        }
      })
    }
  },

  async installApp(context, { appId, contactInfo } = {}){
    try {
      const { data } = await Axios.post(`/apps/${appId}/install`, {
        contactInfo
      })
      return data
    } catch(err) {
      errorHandler({
        error: err,
        parameters: {
          fixedMessageError: 'Erro ao instalar. Tente novamente',
          timeout: 4000
        }
      })
    }
  },

  async uninstallApp(context, appId){
    try {
      const { data } = await Axios.delete(`/apps/installations/${appId}`)
      return data
    } catch(err) {
      errorHandler({
        error: err,
        parameters: {
          fixedMessageError: 'Erro ao desinstalar. Tente novamente',
          timeout: 4000
        }
      })
    }
  },

  async updateApp(context, appId){
    try {
      const { data } = await Axios.post(`/apps/installations/${appId}/update`)
      return data
    } catch(err) {
      errorHandler({
        error: err,
        parameters: {
          fixedMessageError: 'Erro ao atualizar o app. Tente novamente',
          timeout: 4000
        }
      })
    }
  }
}

// getters
const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
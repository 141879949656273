<template>
  <div class="ls-d-flex ls-justify-content-center">
    <div class="ls-col-8">
      <div class="consent-header">
        <div class="ls-d-flex">
          <AppIcon
            :width="48"
            :height="48"
            :url="app && app.icon && app.icon.url"
          />
          <div class="mx-4 chevrons">
            <v-icon color="grey-60 mb-2">
              uil-arrow-right
            </v-icon>
            <v-icon color="grey-60">
              uil-arrow-left
            </v-icon>
          </div>
          <AppIcon
            :width="48"
            :height="48"
            :url="communityLogo"
          />
        </div>

        <div class="consent-summary bold lead-light--text">
          Permitir que <span class="aqua--text">{{ app.displayName }}</span> acesse:
        </div>
      </div>

      <div class="consent-content mt-2">
        <div class="contact-info" v-if="app && app.approval && app.approval == 'manual'">
          <div class="contact-name">
            <div class="lead--text f-16 bold mb-2">Nome do usuário</div>
            <l-input
              v-model="contactInfo.name"
              placeholder="Digite aqui seu nome">
            </l-input>
          </div>
          <div class="contact-telephone mt-3">
            <div class="lead--text f-16 bold mb-2">Telefone</div>
            <l-input placeholder="Digite aqui seu telefone" v-model="contactInfo.telephone"></l-input>
          </div>
          <div class="contact-message mt-3 mb-3">
            <div class="lead--text f-16 bold mb-2">Deixe uma mensagem para o desenvolvedor</div>
            <l-input
              placeholder="Opcional"
              v-model="contactInfo.message"
              type="textarea"
              :rows="4"
              resize="none">
            </l-input>
          </div>
        </div>

        <l-collapse class="mt-3" v-model="currentOpened" v-if="app && app.neededConsents && app.neededConsents.length">
          <l-collapse-item
            :name="index"
            v-for="(consent, index) in app.neededConsents"
            :key="index">
            <template slot="title">
              <div class="ls-d-flex ls-flex">
                <v-icon color="aqua">{{ consent.icon }}</v-icon>
                <div class="ml-3 ls-flex">
                  <div class="lead--text bold f-14 consent-name">{{ consent.name }}</div>
                  <div class="grey-70--text f-12 consent-description">{{ consent.description }}</div>
                </div>
              </div>
            </template>
            <div class="grey-80--text f-14 mt-3">
              O app terá acesso aos dados de:
              <ul class="p-0">
                <li class="p-0" v-for="metadata in consent.metadata" :key="metadata">
                  • {{ metadata }}
                </li>
              </ul>
            </div>
          </l-collapse-item>
        </l-collapse>
      </div>

      <div class="consent-accept-terms grey-70--text mt-2 f-14" v-if="app && (app.termsOfService || app.privacyPolicy)">
        <a class="link--text" :href="app.privacyPolicy" target="_blank" v-if="app.privacyPolicy">
          Política de privacidade
        </a>
        {{ app.privacyPolicy ? 'e' : ''}}
        <a class="link--text" :href="app.termsOfService" target="_blank" v-if="app.termsOfService">
          Termos de serviço
        </a>
        do app {{ app && app.displayName }}
      </div>

      <div class="consent-footer ls-d-flex mb-5 mt-3">
        <l-button class="footer-button bold" type="primary" plain @click="$emit('updateNeededConsents', false)">
          Voltar
        </l-button>
        <l-button class="footer-button bold" type="primary" @click="install()" :loading="$wait.waiting('loading')">
          {{ buttonInstallationLabel }}
        </l-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/AppIcon'
import Environment from '@/environment'
import { mapState, mapActions } from 'vuex'
import Toast from '@/helpers/toast'
import _ from 'lodash'
import { mapWaitingActions } from 'vue-wait'

export default {
  name: 'Consents',

  data() {
    return {
      currentOpened: 0,

      contactInfo: {
        name: null,
        telephone: null,
        message: null
      }
    }
  },

  components: {
    AppIcon
  },

  props: {
    app: {
      type: Object
    },
  },

  computed: {
    ...mapState('layers', ['communityId']),
    communityLogo() {
      return Environment.API_URL + '/media/community/' +  this.communityId + '/icon/png:512x512'
    },

    buttonInstallationLabel() {
      return {
        'automatic': 'Instalar',
        'manual': 'Solicitar instalação'
      }[this.app && this.app.approval] || 'Instalar'
    }
  },

  methods:{
    ...mapActions('appstore', ['installApp']),
    ...mapWaitingActions('appstore', {
      installApp: 'loading',
    }),

    async install() {
      const appInstalled = await this.installApp({ appId: this.app.id, contactInfo: this.contactInfo })
      if(!appInstalled) return

      await this.$emit('updateAppInfo')
      this.$emit('updateNeededConsents', false)

      let successMessage
      const approval = _.get(this.app, 'approval', null)
      if(approval == 'manual'){
        successMessage = 'A instalação desse app foi solicitada. Aguarde ela ser aprovada pela curadoria.'
      } else {
        successMessage = 'Seu app foi instalado com sucesso. Aproveite!'
      }

      Toast.open({
        options: {
          prependIcon: 'check',
        },
        message: successMessage,
        type: 'success',
        position: 'bottom',
        timeout: 5000
      })

      /* global LayersPortal */
      LayersPortal('go', `/settings/app/${this.app.id}?tab=settings`)
    }
  }
}
</script>

<style scoped>
.footer-button {
  flex: 1;
}
.chevrons {
  width: 24px;
}
.consent-summary {
  display: block;
  font-size: 25px;
  min-height: 48px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.consent-name, .consent-description {
  line-height: initial;
}
</style>
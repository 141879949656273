<template>
  <l-button 
    class="installation-button bold" 
    :type="buttonType" 
    :plain="isPlainButton"
    :style="isPlainButton ? { 'background-color': `var(--${buttonType}-light)` } : ''"
    :loading="loading">
    {{ label }}
    <v-icon 
      class="chevron" 
      :color="buttonType" 
      v-if="isPlainButton">
      uil-angle-down
    </v-icon>
  </l-button>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'InstallationButton',

  props: {
    installationStatus: {
      type: String,
      defaut: 'uninstalled'
    },

    approval: {
      type: String,
    },

    loading: {
      type: Boolean
    }
  },

  computed: {
    label(){
      if(this.loading) {
        return {
          'uninstalled': 'Instalando...',
          'installed': 'Desinstalando...',
          'pending_update': 'Atualizando...',
          'pending_approval': 'Desinstalando...'
        }[this.installationStatus]
      }

      if(this.approval == 'manual' && this.installationStatus == 'uninstalled'){
        return 'Solicitar instalação'
      }

      return {
        'uninstalled': 'Instalar',
        'installed': 'Instalado',
        'pending_update': 'Atualizar',
        'pending_approval': 'Em análise'
      }[this.installationStatus] || 'Instalar'
    },

    buttonType() {
      return {
        'uninstalled': 'primary',
        'installed': 'success',
        'pending_update': 'warning',
        'pending_approval': 'warning'
      }[this.installationStatus] || 'primary'
    },

    isPlainButton(){
      return _.includes(['installed', 'pending_update', 'pending_approval'], this.installationStatus)
    }
  },
}
</script>

<style scoped>
.installation-button {
  width: 100%;
  position: relative;
}
.chevron {
  position: absolute!important;
  right: 8px;
  top: 8px;
}
</style>
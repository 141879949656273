<template>
  <div
    v-if="app"
    class="app-card p-3 grey-50-outline ls-d-flex">
    <div class="app-icon ls-flex-none">
      <AppIcon
        :url="app.icon && app.icon.url"
        :width="48"
        :height="48"
      />
    </div>
    <div class="app-content ml-2 ls-flex-column ls-d-flex ls-flex">
      <div class="lead--text f-16 bold">{{ app.displayName }}</div>
      <span class="lead-light--text f-14 ls-flex">
        {{ app.shortDescription }}
      </span>
      <div class="app-installation-status" v-if="app && app.installationStatus && app.installationStatus != 'uninstalled'">
        <InstallationStatusChip
          :installationStatus="app.installationStatus"
        />
      </div>
      <div class="app-author lead-light--text ls-d-flex f-14" v-else-if="app && app.author">
        <span>By</span>
        <div class="link--text bold ml-1 ellipsis-1">
          {{ app.author.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstallationStatusChip from '@/components/InstallationStatusChip'
import AppIcon from '@/components/AppIcon'

export default {
  name: 'AppCard',

  components: {
    InstallationStatusChip,
    AppIcon
  },

  props: {
    app: {
      type: Object
    }
  },
}
</script>

<style scoped>
.app-card {
  width: 100%;
  height: 136px;
  border: 1px solid;
  border-radius: 12px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
.app-card:hover {
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.15);
  border-color: #2d9cdb !important;
}
</style>
<template>
  <div class="collections">
    <template v-if="collections && collections.length">
      <PromotionalCard
        v-for="(collection, i) in collections"
        :promotional="collection && collection.promotional"
        :alias="collection && collection.alias"
        :key="i"
      />
    </template>

    <template v-else>
      <div 
        class="skeleton-promotional grey-30"
        v-for="index in 3"
        :key="index"
      >
        <van-skeleton 
          class="p-0"
          :row="5" 
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PromotionalCard from '@/components/PromotionalCard'

export default {
  name: 'CollectionFeed',

  components: {
    PromotionalCard
  },

  computed: {
    ...mapState('appstore', ['collections'])
  }
}
</script>

<style scoped>
.collections {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.skeleton-promotional {
  padding: 24px;
  border-radius: 12px;
}

@media (min-width: 576px) {
  .collections {
    margin-top: 24px;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .collections {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    margin-bottom: 32px;
  }
}

@media (min-width: 992px) {
  .collections {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    margin-bottom: 32px;
  }
}
</style>
import Vue from 'vue'
import VueWait from 'vue-wait'
import App from './App.vue'
import router from './router'
import store from './store'

// Load plugins
import vuetify from './plugins/vuetify'
import vant from './plugins/vant'
import layers from './plugins/layers-design-system'
import axios from './plugins/axios'

// Load styles
import Overrides from './styles/Overrides.css'
import General from './styles/General.css'
import Grid from './styles/Grid.css'
import Transitions from './styles/Transitions.css'
import Text from './styles/Text.css'
import Colors from './styles/Colors.css'
import Utils from './styles/Utils.css'

Vue.config.productionTip = false

async function init() {
  await store.dispatch('layers/init')

  new Vue({
    // plugins
    router,
    store,
    vant,
    vuetify,
    layers,

    General,
    Overrides,
    Grid,
    Transitions,
    Text,
    Utils,
    Colors,
    axios,
    wait: new VueWait({ useVuex: true }),
    render: h => h(App),
  }).$mount('#app')
}

init()


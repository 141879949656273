<template>
  <v-dialog
    :key="appId"
    :value="overlay"
    @input="closeDialog()"
    max-width="864px">
    <div class="app-detail white">
      <div class="dialog-header ls-d-flex p-3">
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon size="24" color="lead-light">uil-multiply</v-icon>
        </v-btn>
      </div>

      <div class="dialog-content mx-2">
        <Consents
          v-if="neededConsents"
          :app="app"
          @updateNeededConsents="neededConsents = $event"
          @updateAppInfo="updateAppInfo"
        />
        <AppContent
          v-else
          :app="app"
          @updateNeededConsents="neededConsents = $event"
          @updateAppInfo="updateAppInfo"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import Consents from '@/views/AppDetail/Consents'
import AppContent from '@/views/AppDetail/AppContent'

export default {
  name: 'AppDetail',

  components: {
    AppContent,
    Consents
  },

  data() {
    return {
      overlay: true,
      lastRoute: null,

      app: null,
      appId: null,

      currentTab: 0,
      neededConsents: false
    }
  },

  async created() {
    this.updateAppInfo()
  },

  computed: {

    hasHistory () {
      return window.history.length > 2
    },

    canGoBack(){
      return this.hasHistory && (this.lastRoute && this.lastRoute.name)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
        vm.lastRoute = from;
    });
  },

  methods: {
    ...mapActions('appstore', ['fetchApp']),

    async updateAppInfo() {
      const appId = _.get(this.$route, 'params.appId', null)

      if(!appId) {
        this.closeDialog()
      }
      this.appId = appId
      this.app = await this.fetchApp({
        appId: appId,
        retryParams: {
          fixedMessageError: 'Não foi possível carregar as informações desse app',
        },
        onError: this.closeDialog
      })
    },

    closeDialog() {
      this.overlay = false

      if(this.canGoBack){
        return this.$router.back()
      }
      return this.$router.push({ name: 'home' })
    }
  }
}
</script>
<style scoped>
.app-detail {
  min-height: calc(100vh - 86px);
}

</style>
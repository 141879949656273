<template>
  <div class="author-page ls-container" :class="{ 'pt-4': $vuetify.breakpoint.lgAndUp }">
    <div class="author-name lead--text" v-if="author">
      {{ author.name }}
    </div>
    <AppFeed class="mt-5" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppFeed from '@/components/AppFeed'

import _ from 'lodash'

export default {
  name: 'Author',

  components: {
    AppFeed,
  },

  data() {
    return {
      author: null
    }
  },

  watch:{
    '$route.params.id'() {
      this.updateAuthor()
    }
  },

  created() {
    this.updateAuthor()
  },

  methods: {
    ...mapActions('appstore', ['fetchCatalog']),

    updateAuthor() {
      let authorId = _.get(this.$route, 'params.id', null)
      if(!authorId){
        this.$router.back()
        return
      }

      let author = _.get(this.$route, 'params.author', null)
      if(!author){
        author = {
          name: _.get(this.$route, 'query.authorName', null)
        }
      }
      this.author = author
      this.fetchCatalog({ author: authorId })
    }
  },

}
</script>

<style scoped>
.author-name {
  font-weight: bold;
  font-size: 32px;
}
</style>
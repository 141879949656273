<template>
  <v-app id="app" class="white layers-design-system ls-ma-0">
    <router-view
      name="app">
    </router-view>
    <router-view 
      name="header">
    </router-view>
    <router-view 
      name="navigation">
    </router-view>
    <v-main>
      <transition 
        :name="transitionName"
        mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'App',

  data: () => ({
    transitionName: 'slide-left',
  }),

  created() {
    this.setApps([])
  },

  methods:{
    ...mapMutations('appstore', ['setApps'])
  }
};
</script>
<style lang="scss">
$body-font-family: 'Nunito';
.v-application {
  font-family: $body-font-family, sans-serif !important;
}
</style>